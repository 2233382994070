import React from "react";
import { navigate, PageProps } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import { SEO } from "@components";
import { LayoutMissionDesktop, LayoutMissionMobile } from "@components";
import { useAuth } from "@hooks";
import { Route } from "@interfaces";

const SavingsInvestmentsIndex: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(min-width: 1240px)");

  return (
    <>
      <SEO />
      {isMobile ? (
        <LayoutMissionDesktop
          title={"Eliminar"}
          subtitle={"ELIMINAR!!!"}
          number={1}
          onHouseClick={() => navigate(Route.savingsAndInvestment)}
        >
          <div>
            Mas que nada por si lo quieren usar de base, y para poder subir la
            carpeta en el pull request
          </div>
        </LayoutMissionDesktop>
      ) : (
        <LayoutMissionMobile
          title={"Eliminar"}
          subtitle={"ELIMINAR!!!"}
          number={1}
          onHouseClick={() => navigate(Route.savingsAndInvestment)}
        >
          <div>
            Mas que nada por si lo quieren usar de base, y para poder subir la
            carpeta en el pull request
          </div>
        </LayoutMissionMobile>
      )}
    </>
  );
};

export default SavingsInvestmentsIndex;
